import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline } from '@mui/material';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';

const theme = createTheme({
palette: {
    mode: 'light',
    primary: {
    main: '#1976d2',
    },
    secondary: {
    main: '#dc004e',
    },
},
});

function App() {
return (
    <ThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
        <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate to="/login" replace />} />
        </Routes>
    </Router>
    </ThemeProvider>
);
}

export default App;
